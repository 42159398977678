import {extend} from 'flarum/extend';
import app from 'flarum/app';
import CommentPost from 'flarum/components/CommentPost';


export default function () {
   extend(CommentPost.prototype, 'content', function (content) {

        const postHeaderIndex = content.findIndex(item => item.attrs && item.attrs.className === 'Post-header');

        // Insert the new content just after the header
        // or at the very beginning if the header is not found
        content.splice(postHeaderIndex === -1 ? 0 : postHeaderIndex + 1, 0, "hi");
    });
}